import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import styled from "@emotion/styled";
import {Empty, message, Table} from "antd";
import TableEmpty from "../../../components/table/TableEmpty";
import auth from "../../../utils/auth";
import {useSelector} from "react-redux";
import {useRequest} from "ahooks";
import jsonRequest from "../../../utils/request/jsonRequest";
import WithdrawButton from "../../../components/pages/personal/earning/WithdrawButton";
import * as Moment from "moment"

const MainCard = styled.div`
  padding: 32px 24px 23px;
  height: 119px;
  min-width: 732px;
  border-radius: 8px;
  background-image: url("/images/pages/earning/card.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  .amount {
    flex: 1;
    .amountText {
      line-height: 24px;
      font-size: 24px;
      font-family: PingFang SC-Heavy, PingFang SC,sans-serif;
      font-weight: 800;
      color: rgba(0,0,0,0.8);
      :before {
        display: inline-block;
        content: '¥';
        font-size: 14px;
        margin-right: 4px;
      }
    }
    .amountButton {
      padding: 0 12px;
      margin-left: 18px;
      margin-top: 16px;
      line-height: 24px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC,sans-serif;
      font-weight: 400;
      color: rgba(0,0,0,0.6);
      background: rgba(255,255,255,0.6);
      border-radius: 24px 24px 24px 24px;
      border: none;
      opacity: 1;
    }
  }
  
  .withdraw {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-family: PingFang SC-Medium, PingFang SC,sans-serif;
    .withdrawText {
      line-height: 24px;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0,0,0,0.6);
      &.clickable {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .withdrawButton {
      padding: 0 24px;
      height: 32px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255,255,255,0.8);
      background: rgba(0,0,0,0.95);
      border-radius: 4px 4px 4px 4px;
      border: none;
      opacity: 1;
      :hover {
        cursor: pointer;
      }
    }
  }
`

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC,sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
`

const Earning = () => {

  auth.authorize()
  const {account} = useSelector((s: any) => s)

  const {run, data} = useRequest(() => {
    return jsonRequest('/account/wallet', {

    }).then(rsp => {
      if (!rsp.success) {
        message.error(rsp.message)
      }
      return rsp.data.data;
    })
  })

  const {run: loadWithdraw, data: withdraw, loading: loadingWithdraw} = useRequest(() => {
    return jsonRequest('/account/wallet/record').then(rsp => {
      if (!rsp.success) {
        message.error(rsp.message)
        return;
      }
      return rsp.data.data;
    })
  })

  const load = () => {
    run()
    loadWithdraw()
  }

  return data && (<>
    <PersonalLayout selected='earning'>
      <PersonalBody
        title='数字钱包'
      >
        <MainCard>
          <div className="amount">
            <div className="amountText">{data?.amount ?? 0}</div>
            <button className="amountButton">可提现金额</button>
          </div>
          <div className="withdraw">
            <WithdrawButton
              wallet={data}
              reload={load}
            />
          </div>
        </MainCard>
        <Title>收益明细</Title>
        <Table
          rowKey='id'
          size='small'
          columns={[
            {
              title: '名称',
              key: 'name',
              dataIndex: 'name',
            },
            {
              title: '金额',
              key: 'amount',
              render: (_, {amount}) => {
                return Math.abs(amount)
              }
            },
            {
              title: '收支',
              key: 'type',
              render: (_, {amount}) => {
                return amount > 0 ? '收入' : '支出'
              }
            },
            {
              title: '日期',
              key: 'date',
              render: (_, {createdAt}) => {
                return Moment(createdAt).format("YYYY-MM-DD HH:mm")
              }
            },
            {
              title: '状态',
              key: 'status',
              render: (_, record) => {
                if (record.type === 'withdraw') {
                  switch (record.withdraw.status) {
                    case 'auditing':
                      return '审核中';
                    case 'auditSuccess':
                      return '提现中';
                    case 'withdrawing':
                      return '提现中';
                    case 'fail':
                    case 'withdrawFail':
                      return '提现失败';
                  }
                }
                return '已完成';
              }
            }
          ]}
          dataSource={withdraw}
          locale={{
            emptyText: <TableEmpty/>
          }}
        />
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default Earning
