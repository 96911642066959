import * as React from "react";
import FormModal, {FormModalProps} from "../../../modals/formModal";
import {Button, Form, Input} from "antd";
import jsonRequest from "../../../../utils/request/jsonRequest";
import announceModal from "../../../modals/announceModal";
import {useEffect, useMemo, useState} from "react";
import SmsInput from "../../sign/SmsInput";

export type WithdrawModalProps = {
  wallet: any;
  phone: string;
} & FormModalProps

export default (props: WithdrawModalProps) => {
  const {
    wallet,
    phone,
    open,
    ...modalProps
  } = props

  const [form] = Form.useForm();
  const [stage, setStage] = useState<'account' | 'sms'>('account')

  const onFinish = (values) => {
    return jsonRequest(`/account/withdrawAccount`, {
      method: 'PUT',
      data: values,
    }).then(rsp => {
      announceModal({
        type: 'success',
        title: '绑定账户',
        message: '提现账户绑定成功',
      })
      return rsp;
    })
  }

  const initialValues = useMemo(() => (
    {
      bank: wallet.withdrawAccount?.bank ?? '',
      bankAccount: wallet.withdrawAccount?.bankAccount ?? '',
      alipayAccount: wallet.withdrawAccount?.alipayAccount ?? '',
    }
  ), [wallet])

  useEffect(() => {
    if (open) {
      setStage('account')
      Object.keys(initialValues).forEach(k => {
        form.setFieldValue(k, initialValues[k])
      })
    }
  }, [open, initialValues])

  return (<>
    <FormModal
      title='绑定收款账号'
      footer={stage === 'account' ? (
        <Button type="primary" onClick={() => setStage("sms")}>下一步</Button>
      ) : undefined}
      width={480}
      {...{open}}
      {...modalProps}
      formProps={{
        labelCol: {
          span: 5,
        },
        wrapperCol: {
          span: 19,
        },
        form,
        // initialValues,
        onFinish,
      }}
    >
      <Form.Item label='开户银行' name='bank' hidden={stage !== 'account'}>
        <Input placeholder='请输入开户银行名称'/>
      </Form.Item>
      <Form.Item label='银行卡号' name='bankAccount' hidden={stage !== 'account'}>
        <Input placeholder='请输入银行卡号'/>
      </Form.Item>
      <Form.Item label='支付宝号' name='alipayAccount' hidden={stage !== 'account'}>
        <Input placeholder='请输入支付宝号码'/>
      </Form.Item>
      {
        stage === 'sms' && (
          <>
            <Form.Item label='绑定手机'>{phone}</Form.Item>
            <Form.Item label='验证码' name='code'>
              <SmsInput
                phone='13420611739'
                url='/account/withdrawAccount/sms'
              />
            </Form.Item>
          </>
        )
      }
    </FormModal>
  </>)
}
